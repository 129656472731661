import "./Footer.css";

const Footer = () => <footer>
	<ul className={"container"}>
		<li><a href={"https://www.soldan.de/agb"} target={"_blank"} rel={"noreferrer"}>AGB</a></li>
		<li><a href={"https://www.soldan.de/soldan-datenschutz"} target={"_blank"} rel={"noreferrer"}>Datenschutz</a></li>
		<li><a href={"https://www.soldan.de/impressum"} target={"_blank"} rel={"noreferrer"}>Impressum</a></li>
		<li><a href={"https://www.soldan.de"} target={"_blank"} rel={"noreferrer"}>soldan.de</a></li>
	</ul>
</footer>;

export default Footer;