import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { Action, IAction } from "./actions";
import { Session, Setting } from "./storage";
import { IResult, ISearchtypeResult, ISourceType } from "./interfaces";
import { IMessage } from "./components/UserMessage";

const defaultState = {
	environment: "",
	
	// User information
	username:       "",
	jwt:            Session.load( "jwt", "" ),
	intrasearchJwt: "",
	websocket:      null,
	LeReTo:         "",
	
	allSources:      [],
	linkList:        [],
	userMessages:    [],
	openingSSOLinks: [],
	
	// Search request params
	selectedSources:          Setting.load( "selectedSources", [] ),
	searchtext:               "",
	page:                     1,
	facets:                   {},
	intrasearchFacetLimit:    {},
	intrasearchExtendedFacet: {},
	
	// Search request results
	results:     [],
	resultsView: ""
};

export interface IState
{
	environment: string,
	
	username: string,
	jwt: string,
	intrasearchJwt: string,
	websocket: WebSocket | null,
	LeReTo: string,
	
	allSources: ISourceType[],
	linkList: any,
	userMessages: IMessage[],
	openingSSOLinks: string[],
	
	selectedSources: string[],
	searchtext: string,
	page: number,
	facets: { [key: string]: string[] },
	intrasearchFacetLimit: { [key: string]: number },
	intrasearchExtendedFacet: { [key: string]: number },
	
	results: ISearchtypeResult[],
	resultsView: string
}

const reducers = (
	state: IState = defaultState,
	action: IAction
) =>
{
	switch ( +action.type )
	{
		case Action.SET:
		{
			const { name, value } = action;
			
			return Object.assign(
				{},
				state,
				{
					[name]: value
				}
			);
		}
		
		case Action.MERGE:
		{
			const { name, value } = action;
			
			return Object.assign(
				{},
				state,
				{
					[name]: Object.assign(
						{},
						state[name],
						value
					)
				}
			);
		}
		
		case Action.MULTI_SET:
		{
			const { values } = action;
			
			return Object.assign(
				{},
				state,
				values
			);
		}
		
		case Action.FILTER:
		{
			const { name, filter } = action;
			
			return Object.assign(
				{},
				state,
				{
					[name]: state[name].filter( filter )
				}
			);
		}
		
		case Action.CONCAT:
		{
			const { name, value } = action;
			
			return Object.assign(
				{},
				state,
				{
					[name]: state[name].concat( value )
				}
			);
		}
		
		case Action.SHOWUSERMESSAGE:
		{
			return Object.assign(
				{},
				state,
				{
					userMessages: state.userMessages.filter(
						userMessage => !( userMessage.type === action.value.type && userMessage.message === action.value.message )
					).concat( action.value )
				}
			);
		}
		
		case Action.PROCESS_SEARCHRESULT:
		{
			const { result } = action;
			
			// Alte Suchanfrageantworten verwerfen
			// 1. Suchtyp muss noch ausgewählt sein
			// 2. Suchtext muss noch gleich sein
			// TODO Facetten ergänzen
			if (
				!state.selectedSources.includes( result.name )
				|| result.searchtext !== state.searchtext
			)
			{
				return state;
			}
			
			// Erste Seite Suchergebnisse einfach übernehmen
			if ( result.page === 1 )
			{
				return Object.assign(
					{},
					state,
					{
						results: state.results.filter( resultsIterator => resultsIterator.name !== result.name ).concat( result )
					}
				);
			}
			
			// Ansonsten Suchergebnis der ersten Seite heraussuchen und nur die neuen Ergebnisse für eine Seite übernehmen.
			const searchresultType = state.results.find( ( searchresultType: ISearchtypeResult ) => searchresultType.name === result.name );
			if ( !searchresultType )
			{
				return state;
			}
			
			const startIndex = searchresultType.resultsPerPage * ( result.page - 1 );
			
			// Neues Array mit Länge der Suchergebnisse erzeugen
			const rows = new Array( searchresultType.totalResults );
			// Bereits geladene Suchergebnisse übernehmen
			searchresultType.rows.forEach( ( result: IResult, index: number ) => rows[index] = result );
			// Neue Suchergebnisse hinzufügen
			result.rows.forEach( ( result: IResult, index: number ) => rows[startIndex + index] = result );
			
			return Object.assign(
				{},
				state,
				{
					results: state.results.slice().map(
						( searchtypeResult: ISearchtypeResult ) => searchtypeResult.name === result.name
							? Object.assign(
								{},
								searchtypeResult,
								{ rows }
							)
							: searchtypeResult
					)
				}
			);
		}
		
		case Action.SELECTRESULTSVIEW:
		{
			const { resultsView: newResultsView } = action;
			const { resultsView, results }        = state;
			
			// Noch kein View ausgewählt -> automatisch den ersten mit Ergebnissen auswählen
			if ( newResultsView === "" && resultsView === "" )
			{
				const partnerSources = state.selectedSources.filter(
					source => state.allSources[0].options.find( sourceIterator => sourceIterator.id === source )
				);
				
				// Wenn eine Partnerquelle ausgewählt wurde diese zwingend zuerst auswählen,
				// nur eine freie Suche auswählen wenn alle gewählten Partnersuchen 0 Suchergebnisse geliefert haben.
				if ( partnerSources.length > 0 )
				{
					// Prüfen ob schon ein Partnerquellenergebnis vorliegt und dieses auswählen:
					const partners = partnerSources.filter( partnerSource => results.find( result => result.name === partnerSource && result.totalResults > 0 ) );
					if ( partners.length > 0 )
					{
						return Object.assign(
							{},
							state,
							{
								resultsView: partners[0]
							}
						);
					}
					
					const emptyPartners = partnerSources.filter( partnerSource => results.find( result => result.name === partnerSource && result.totalResults === 0 ) );
					if ( emptyPartners.length < partnerSources.length )
					{
						return state;
					}
				}
				
				for ( let i = 0; i < results.length; i++ )
				{
					const resultSet = results[i];
					
					if ( resultSet.totalResults > 0 )
					{
						return Object.assign(
							{},
							state,
							{
								resultsView: resultSet.name
							}
						);
					}
				}
			}
			else if ( newResultsView !== "" )
			{
				return Object.assign(
					{},
					state,
					{
						resultsView: newResultsView,
						page:        1
					}
				);
			}
			
			return state;
		}
		
		case Action.REFRESHFACET:
		{
			const { facet } = action;
			
			return Object.assign(
				{},
				state,
				{
					results: state.results.slice().map(
						( searchtypeResult: ISearchtypeResult ) => searchtypeResult.name === state.resultsView
							? Object.assign(
								{},
								searchtypeResult,
								{
									facets: searchtypeResult.facets.slice().map(
										facetIterator => facetIterator.name === facet.name
											? facet
											: facetIterator
									)
								}
							)
							: searchtypeResult
					)
				}
			);
		}
		
		case Action.TOGGLEFACET:
		{
			const { facetValue } = action;
			
			return Object.assign(
				{},
				state,
				{
					facets:  Object.assign(
						{},
						state.facets,
						{
							[state.resultsView]:
								state.facets.hasOwnProperty( state.resultsView )
									? (
										state.facets[state.resultsView].includes( facetValue )
											? [ ...state.facets[state.resultsView] ].filter( valueIterator => valueIterator !== facetValue )
											: [ ...state.facets[state.resultsView] ].concat( [ facetValue ] )
									)
									: [ facetValue ]
						}
					),
					results: state.results.slice().map(
						( searchtypeResult: ISearchtypeResult ) => searchtypeResult.name === state.resultsView
							? Object.assign(
								{},
								searchtypeResult,
								{ rows: [] }
							)
							: searchtypeResult
					),
					page:    1
				}
			);
		}
		
		default:
			return state;
	}
};

export const reduxStore = createStore(
	reducers,
	applyMiddleware(
		thunkMiddleware
	)
);
