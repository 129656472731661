import "./Sources.css";
import { useMemo } from "react";
import { showUserMessage } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../reducer";
import { ISource } from "../interfaces";
import Checkbox from "react-three-state-checkbox";
import { Setting } from "../storage";
import { MessageType } from "./UserMessage";
import Loader from "./Loader";
import { ExternalLinkIcon } from "../Icons";

interface SourceProps
{
	source: ISource,
	onChange: ( isChecked: boolean, ids: string[] ) => void,
	selectedSources: string[],
	small: boolean
}

const Source = ( { source, onChange, selectedSources, small }: SourceProps ) =>
{
	const LeReTo                                   = useSelector( ( state: IState ) => state.LeReTo );
	const { id = 0, img, name, options, disabled, url } = source;
	
	const ids = useMemo(
		() =>
		{
			const getChildIds = ( source: ISource ): string[] =>
			{
				if ( source.disabled )
				{
					return [];
				}
				
				if ( source.id )
				{
					return [ source.id ];
				}
				else if ( source.options.length > 0 )
				{
					let ids: string[] = [];
					source.options.forEach(
						option =>
						{
							ids = ids.concat( getChildIds( option ) );
						}
					);
					return ids;
				}
				return [];
			};
			
			return getChildIds( source );
		},
		[ source ]
	);
	
	const isChecked = useMemo(
		() =>
		{
			if ( !id )
			{
				if ( ids.length === 0 )
				{
					return false;
				}
				
				return ids.filter( idIterator => !selectedSources.includes( idIterator ) ).length === 0;
			}
			
			return selectedSources.includes( id );
		},
		[ id, ids, selectedSources ]
	);
	
	const isIndeterminate = useMemo(
		() =>
		{
			if ( id )
			{
				return false;
			}
			
			return ids.length > 0
				&& ids.filter( idIterator => !selectedSources.includes( idIterator ) ).length > 0
				&& selectedSources.filter( idIterator => ids.includes( idIterator ) ).length > 0;
		},
		[ id, ids, selectedSources ]
	);
	
	return <li className={`${disabled ? "disabled" : ""} ${img ? "img" : ""}`}>
		<label>
			<Checkbox indeterminate={isIndeterminate} checked={isChecked} disabled={disabled} onChange={() => onChange( isChecked, ids )}/>
			{
				img
					? <img src={img} alt={name} title={name}/>
					: <span>{name}</span>
			}
			{
				url &&
					<a href={url} target={"_blank"} rel={"noreferrer"} title={"In neuem Fenster anzeigen"}><ExternalLinkIcon/></a>
			}
			{
				disabled && name.substring( 0, 11 ) === "Intrasearch" && !url &&
				<a href={"https://www.intrasearch.de"} target={"_blank"} rel={"noreferrer"} title={"In neuem Fenster anzeigen"}><ExternalLinkIcon/></a>
			}
		</label>
		{
			options && options.length > 0 &&
			<ul>
				{options.map( ( source: ISource, index: number ) => <Source key={`source-${index}`} source={source} onChange={onChange} selectedSources={selectedSources}
				                                                            small={small}/> )}
				{
					!small && name === "Meine Daten" && LeReTo !== "" &&
					<iframe
						title={"LeReTo PDF-Dropzone"}
						src={`https://soldan.lereto.com/viewer?token=${LeReTo}`}
						width="234px"
						height="234px"
						frameBorder="no"
						style={{ marginTop: "2rem", border: "none" }}/>
				}
			</ul>
		}
	</li>;
};

interface SourcesProps
{
	onChange: ( isChecked: boolean, ids: string[] ) => void,
	searchSources: string[],
	small: boolean
}

const Sources = ( { onChange, searchSources, small }: SourcesProps ) =>
{
	const allSources = useSelector( ( state: IState ) => state.allSources );
	const dispatch   = useDispatch();
	
	const onSave = () =>
	{
		const filteredSources = searchSources.filter(
			source => allSources.find( category => category.options.find( sourceIterator => sourceIterator.id === source && !sourceIterator.disabled ) )
		);
		
		const savedState = Setting.load( "selectedSources" );
		
		if ( filteredSources.length === 0 )
		{
			if ( savedState === null )
			{
				dispatch( showUserMessage( MessageType.INFO, "Sie müssen mindestens eine Quelle auswählen." ) );
				return;
			}
			
			Setting.remove( "selectedSources" );
			dispatch( showUserMessage( MessageType.INFO, "Quellenauswahl gelöscht." ) );
		}
		else
		{
			Setting.save( "selectedSources", filteredSources );
			dispatch( showUserMessage( MessageType.INFO, "Quellenauswahl gespeichert." ) );
		}
	};
	
	return <div className={"Sources"}>
		<h3>
			<span>In welchen Datenquellen möchten Sie suchen?</span>
			<button onClick={onSave}>Auswahl speichern</button>
		</h3>
		<ul>
			{
				allSources.length === 0 &&
				<li className={"isLoading"}>
					<Loader/>
					<div>Quellen werden geladen...</div>
				</li>
			}
			{
				allSources.map( ( source: ISource, index: number ) => <Source key={`source-${index}`} source={source} onChange={onChange}
				                                                              selectedSources={searchSources} small={small}/> )
			}
		</ul>
	</div>;
};

export default Sources;