import { openInIntrasearch, openSSOLink } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../reducer";
import "./Result.css";
import { IResult } from "../interfaces";
import Loader from "./Loader";

interface IJSONField
{
	[key: string]: string
}

interface IJSONLink
{
	url: string,
	method: string,
	fields: IJSONField
}

const Result = ( { id, title, subTitle, link, date, text }: IResult, index: number ) =>
{
	const dispatch        = useDispatch();
	const resultsView     = useSelector( ( state: IState ) => state.resultsView );
	const openingSSOLinks = useSelector( ( state: IState ) => state.openingSSOLinks );
	
	if ( resultsView === "INTRASEARCH" )
	{
		return <li key={`result-${index}`}>
			<button onClick={() => id && dispatch( openInIntrasearch( id ) )} dangerouslySetInnerHTML={{ __html: title || "- kein Titel -" }}/>
			<div className={"location"}>{link}</div>
			{text && <div dangerouslySetInnerHTML={{ __html: text }} className={"text"}/>}
			<div className={"date"}>{date}</div>
		</li>;
	}
	
	let titleLink;
	if ( link.substring( 0, 5 ) === "FORM:" )
	{
		const decodedParams: IJSONLink = JSON.parse( link.substring( 5 ) );
		
		titleLink = <form action={decodedParams.url} method={decodedParams.method}>
			{
				Object.keys( decodedParams.fields ).map(
					( name: string ) => <input key={`result-${index}-${name}`} type={"hidden"} name={name} value={decodedParams.fields[name]}/>
				)
			}
			<button>{title || "- kein Titel -"}</button>
		</form>;
	}
	else if ( link.substring( 0, 4 ) === "SSO:" )
	{
		const token     = link.substring( 4 );
		const isLoading = openingSSOLinks.includes( token );
		
		titleLink = <>
			<button className={isLoading ? "loading" : undefined} onClick={() => dispatch( openSSOLink( token ) )}>
				{title || "- kein Titel -"}
				{isLoading && <Loader size={"1em"}/>}
			</button>
		</>;
	}
	else
	{
		titleLink = <a href={`${link}`} target={"_blank"} rel={"noreferrer"}>{title || "- kein Titel -"}</a>;
	}
	
	return <li key={`result-${index}`}>
		{titleLink}
		{subTitle && <span className={"subtitle"}>{subTitle}</span>}
		{text && <div dangerouslySetInnerHTML={{ __html: text }} className={"text"}/>}
		{
			date &&
			<div className={"date"}>{date}</div>
		}
	</li>;
};

export default Result;