import "./Navigation.css";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { revision, version } from "../const";
import { useSelector } from "react-redux";
import { IState } from "../reducer";

interface ILink
{
	url: string,
	description: string
}

const DatabaseAndServices = () =>
{
	const linkList = useSelector( ( state: IState ) => state.linkList );
	
	return <div className={"advancedMenu"}>
		<ul>
			{
				linkList.map(
					( link: ILink ) => <li key={link.url}>
						<a href={link.url} target={"_blank"} rel={"noreferrer"}>{link.description}</a>
					</li>
				)
			}
		</ul>
	</div>;
};

const Contact = () => <div className={"advancedMenu"}>
	<ul>
		<li>Soldan Fachmedienberatung</li>
		<li className={"text"}>Telefon: 0201 8612 380</li>
		<li className={"text"}>E-Mail: datenbanken@soldan.de</li>
	</ul>
</div>;

const Navigation = () =>
{
	const [ showAdvancedMenu, setShowAdvancedMenu ] = useState( "" );
	const searchtext                                = useSelector( ( state: IState ) => state.searchtext );
	
	// @ts-ignore version wird im Buildprozess ersetzt.
	const versionText = version === "trunk" ? `${version} r${revision}` : version;
	
	return <nav>
		<span className={"version"}>Version {versionText}</span>
		<ul className={"container"}>
			<li>
				<NavLink exact={true} activeClassName={"active"} onFocus={() => setShowAdvancedMenu( "" )} to={"/"}>Start</NavLink>
			</li>
			<li className={showAdvancedMenu === "databases" ? "active" : ""} onMouseEnter={() => setShowAdvancedMenu( "databases" )}
			    onMouseLeave={() => setShowAdvancedMenu( "" )}>
				<button className={"noAction"} onFocus={() => setShowAdvancedMenu( "databases" )}>Datenbanken und Dienste</button>
				{showAdvancedMenu === "databases" && <DatabaseAndServices/>}
			</li>
			<li>
				<a onFocus={() => setShowAdvancedMenu( "" )} href={"http://www.soldan.de/hilfe/"} rel={"noreferrer"} target={"_blank"}>Hilfe</a>
			</li>
			<li onMouseEnter={() => setShowAdvancedMenu( "contact" )} onMouseLeave={() => setShowAdvancedMenu( "" )}>
				<button className={"noAction"} onFocus={() => setShowAdvancedMenu( "contact" )} onBlur={() => setShowAdvancedMenu( "" )}>Kontakt</button>
				{showAdvancedMenu === "contact" && <Contact/>}
			</li>
			{
				searchtext !== "" &&
				<li>
					<NavLink activeClassName={"active"} onFocus={() => setShowAdvancedMenu( "" )} to={"/results"}>Trefferliste</NavLink>
				</li>
			}
		</ul>
	</nav>;
};

export default Navigation;