import { Fragment, useEffect, useMemo } from "react";
import { openSSOLink, search } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../reducer";
import "./Results.css";
import ResultsSources from "./ResultsSources";
import Pagination from "./Pagination";
import { useResult } from "../customHooks";
import { IResult } from "../interfaces";
import Loader from "./Loader";
import { Redirect } from "react-router-dom";
import Search from "./Search";
import Facets from "./Facets";
import { ExternalLinkIcon } from "../Icons";
import Result from "./Result";

const Results = () =>
{
	const dispatch    = useDispatch();
	const searchtext  = useSelector( ( state: IState ) => state.searchtext );
	const page        = useSelector( ( state: IState ) => state.page );
	const facets      = useSelector( ( state: IState ) => state.facets );
	const resultsView = useSelector( ( state: IState ) => state.resultsView );
	
	const allSources = useSelector( ( state: IState ) => state.allSources );
	const results    = useSelector( ( state: IState ) => state.results );
	const result     = useResult();
	const resultList = useMemo(
		(): IResult[] =>
		{
			if ( !result )
			{
				return [];
			}
			
			const first = ( page - 1 ) * result.resultsPerPage;
			const last  = page * result.resultsPerPage - 1;
			
			return result.rows.filter( ( _: IResult, index: number ) => index >= first && index <= last );
		},
		[ result, page ]
	);
	
	// Suchtypspezifische Suchergebnisse laden, wenn geblättert oder eine Facette ausgewählt wurde
	useEffect(
		() =>
		{
			if ( resultsView !== "" )
			{
				dispatch( search( resultsView ) );
			}
			window.scrollTo( {
				top:      0,
				behavior: "smooth"
			} );
		},
		[ dispatch, facets, page, resultsView ]
	);
	
	const noResultsFound = useMemo(
		() => results.length > 0 && !result,
		[ results, result ]
	);
	
	const source = useMemo(
		() =>
		{
			if ( !result )
			{
				return null;
			}
			
			for ( let key in allSources )
			{
				for ( let optionKey in allSources[key].options )
				{
					if ( allSources[key].options[optionKey].id === result.name )
					{
						return allSources[key].options[optionKey];
					}
				}
			}
			
			return null;
		},
		[ allSources, result ]
	);
	
	const resultsTitle = useMemo(
		() =>
		{
			if ( !result )
			{
				return null;
			}
			
			if ( !source )
			{
				return result.name;
			}
			
			return source.name;
		},
		[ result, source ]
	);
	
	// Zur Startseite umleiten wenn kein Suchbegriff vorhanden ist, ansonsten Ergebnisseite anzeigen
	return searchtext === ""
		? <Redirect to={"/"}/>
		: <div className={"Results"}>
			<div className={"sidebar"}>
				<Search small={true}/>
				<ResultsSources/>
				{
					result?.facets &&
					<Facets/>
				}
			</div>
			<div className={"resultlist"}>
				{
					results.length === 0
						? <div className={"isLoading"}>
							<Loader/>
							<div>Suchergebnisse werden geladen...</div>
						</div>
						: <Fragment>
							<div className={"title"}>
								{
									result && result.url &&
									(
										result.url.substring( 0, 4 ) === "SSO:"
											? <button onClick={() => dispatch( openSSOLink( result.url.substring( 4 ) ) )} title={"In neuem Fenster anzeigen"}>
												<ExternalLinkIcon/>
											</button>
											: <a href={result.url} target={"_blank"} rel={"noreferrer"}><ExternalLinkIcon/></a>
									)
								}
								Suchergebnisse {resultsTitle} {result && <Fragment>({result.totalResults.toLocaleString()})</Fragment>}
							</div>
							<Pagination/>
							{noResultsFound && <div className={"isLoading"}>Keine Suchergebnisse gefunden.</div>}
							{
								result && resultList.length === 0 && <div className={"isLoading"}>
									<Loader/>
									<div>Weitere Suchergebnisse werden geladen...</div>
								</div>
							}
							<ul>
								{resultList.map( ( result, index ) => <Result key={`result-${index}`} {...result}/> )}
							</ul>
							<Pagination/>
						</Fragment>
				}
			</div>
		</div>;
};

export default Results;