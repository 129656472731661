export const Setting = {
	
	load: ( name: string, defaultValue: any = null ) =>
	      {
		      const value = localStorage.getItem( name );
		
		      // Wert ist noch nicht gecacht
		      if ( value === null )
		      {
			      return defaultValue;
		      }
		
		      try
		      {
			      return JSON.parse( value );
		      }
		      catch ( e )
		      {
			      localStorage.removeItem( name );
			      return defaultValue;
		      }
	      },
	
	save: ( name: string, value: any ) =>
	      {
		      localStorage.setItem( name, JSON.stringify( value ) );
	      },
	
	remove: ( name: string ) =>
	        {
		        localStorage.removeItem( name );
	        }
};

export const Session = {
	
	load: ( name: string, defaultValue: any = null ) =>
	      {
		      const value = sessionStorage.getItem( name );
		
		      // Wert ist noch nicht gecacht
		      if ( value === null )
		      {
			      return defaultValue;
		      }
		
		      try
		      {
			      return JSON.parse( value );
		      }
		      catch ( e )
		      {
			      sessionStorage.removeItem( name );
			      return defaultValue;
		      }
	      },
	
	save: ( name: string, value: any ) =>
	      {
		      sessionStorage.setItem( name, JSON.stringify( value ) );
	      },
	
	remove: ( name: string ) =>
	        {
		        sessionStorage.removeItem( name );
	        }
};
