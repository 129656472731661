import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Results from "./components/Results";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useRef } from "react";
import { fetchUserInformation } from "./actions";
import UserMessages from "./components/UserMessage";
import { CSSTransition } from "react-transition-group";
import Search from "./components/Search";
import JWT from "./components/JWT";
import { IState } from "./reducer";

const routes = [
	{ path: "/", name: "Startseite", Component: Search },
	{ path: "/jwt/:jwt", name: "JWT", Component: JWT },
	{ path: "/results", name: "Suchergebnisse", Component: Results }
];

const App = () =>
{
	const jwt      = useSelector( ( state: IState ) => state.jwt );
	const username = useSelector( ( state: IState ) => state.username );
	const dispatch = useDispatch();
	useEffect(
		() =>
		{
			if ( jwt && !username )
			{
				dispatch( fetchUserInformation() );
			}
		},
		[ dispatch, jwt, username ]
	);
	const nodeRef = useRef( null );
	
	return <div className="App">
		<BrowserRouter>
			{
				jwt && <Fragment>
					<Header/>
					<UserMessages/>
				</Fragment>
			}
			<Switch>
				{routes.map( ( { name, path, Component } ) => (
					<Route key={path} exact path={path}>
						{( { match } ) =>
							<CSSTransition
								nodeRef={nodeRef}
								in={match != null}
								timeout={{ enter: 200, exit: 0 }}
								classNames="page"
								unmountOnExit
							>
								<main ref={nodeRef} className={`container page-${name}`}>
									<Component/>
								</main>
							</CSSTransition>
						}
					</Route>
				) )}
				<Route>
					<Redirect to={"/"}/>
				</Route>
			</Switch>
		</BrowserRouter>
		<Footer/>
	</div>;
};

export default App;
