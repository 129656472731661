import "./UserMessage.css";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../reducer";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { storeFilter, storeSet } from "../actions";

const Cross = () =>
	<svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<g transform="translate(-6 -6)">
			<path
				d="M18.695,16l6.752-6.752a1.886,1.886,0,0,0,0-2.668l-.027-.027a1.886,1.886,0,0,0-2.668,0L16,13.305,9.248,6.553a1.886,1.886,0,0,0-2.668,0l-.027.027a1.886,1.886,0,0,0,0,2.668L13.305,16,6.553,22.752a1.886,1.886,0,0,0,0,2.668l.027.027a1.886,1.886,0,0,0,2.668,0L16,18.695l6.752,6.752a1.886,1.886,0,0,0,2.668,0l.027-.027a1.886,1.886,0,0,0,0-2.668Z"
				fill="#ffffff"/>
		</g>
	</svg>;

export enum MessageType
{
	ERROR = "error",
	INFO  = "info"
}

export interface IMessage
{
	id: number,
	type: MessageType,
	message: string
}

interface UserMessageProps
{
	userMessage: IMessage
}

const UserMessage = ( { userMessage }: UserMessageProps ) =>
{
	const nodeRef = useRef( null );
	
	const dispatch          = useDispatch();
	const removeUserMessage = () => dispatch( storeFilter( "userMessages", ( userMessageIterator: IMessage ) => userMessageIterator.id !== userMessage.id ) );
	
	return <div ref={nodeRef} className={`UserMessage ${userMessage?.type}`} onClick={removeUserMessage} title={"Benachrichtigung entfernen."}>
		<button className={"icon"} onClick={removeUserMessage} title={"Benachrichtigung ausblenden."}><Cross/></button>
		<div className={"text"}>{userMessage?.message}</div>
	</div>;
};

const UserMessages = () =>
{
	const userMessages = useSelector( ( state: IState ) => state.userMessages );
	
	const nodeRef                 = useRef<HTMLInputElement>( null );
	const [ isFixed, setIsFixed ] = useState( false );
	
	const watch = useCallback(
		() =>
		{
			if ( window.pageYOffset > 200 )
			{
				if ( !isFixed )
				{
					setIsFixed( true );
				}
			}
			else
			{
				setIsFixed( false );
			}
		},
		[ isFixed ]
	);
	
	useEffect(
		() =>
		{
			window.addEventListener( "scroll", watch );
			
			return () => window.removeEventListener( "scroll", watch );
		},
		[ watch ]
	);
	
	const dispatch           = useDispatch();
	const removeUserMessages = () => dispatch( storeSet( "userMessages", [] ) );
	
	return <Fragment>
		{
			userMessages.length > 0 && isFixed &&
			<div style={{ height: userMessages.length === 1 ? 60 : ( 60 * userMessages.length + 58 ) }}/>
		}
		<div ref={nodeRef} className={`UserMessages container ${isFixed ? "fixed" : ""}`}>
			{
				userMessages.map(
					userMessage => <UserMessage key={`userMessage-${userMessage.id}`} userMessage={userMessage}/>
				)
			}
			{
				userMessages.length > 1 &&
				<button className={"clearAllUserMessages"} onClick={removeUserMessages}>Alle Benachrichtigungen ausblenden</button>
			}
		</div>
	</Fragment>;
};

export default UserMessages;