import { createSelector } from "reselect";
import { IState } from "./reducer";
import { ISearchtypeResult } from "./interfaces";
import { useSelector } from "react-redux";

const resultSelector   = createSelector(
	( state: IState ): ISearchtypeResult[] => state.results,
	( _: IState, resultsView: string ): string => resultsView,
	( results: ISearchtypeResult[], resultsView: string ): ISearchtypeResult | null =>
	{
		if ( resultsView === "" )
		{
			return null;
		}
		
		const searchtypeResult = results.find( ( searchtypeResult: ISearchtypeResult ) => searchtypeResult.name === resultsView );
		if ( !searchtypeResult )
		{
			return null;
		}
		
		return searchtypeResult;
	}
);
export const useResult = ( resultId: string = "" ): ISearchtypeResult | null => useSelector( ( state: IState ) => resultSelector( state, resultId !== "" ? resultId : state.resultsView ) );

const facetValuesSelector           = createSelector(
	( state: IState ): { [key: string]: string[] } => state.facets,
	( _: IState, resultsView: string ): string => resultsView,
	( facets: { [key: string]: string[] }, resultsView: string ): string[] =>
	{
		if ( resultsView === "" || !facets.hasOwnProperty( resultsView ) )
		{
			return [];
		}
		
		return facets[resultsView];
	}
);
export const useSelectedFacetValues = ( resultId: string = "" ): string[] => useSelector( ( state: IState ) => facetValuesSelector( state, resultId !== "" ? resultId : state.resultsView ) );
