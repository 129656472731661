import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { reduxStore } from "./reducer";
import { apiUrl } from "./const";

window.onerror = (
	errorMsg,
	errorUrl = window.location.href,
	lineNumber,
	columnNumber,
	errorObj
) =>
{
	fetch(
		`${apiUrl}/rest/error`,
		{
			credentials: "include",
			method:      "POST",
			headers:     {
				"content-type": "application/json"
			},
			body:        JSON.stringify( {
				errorMsg,
				errorUrl,
				lineNumber,
				columnNumber,
				pageTitle:       window.document.title,
				os:              window.navigator.platform,
				userAgentClient: window.navigator.userAgent,
				errorStack:      errorObj?.stack,
				cookie:          window.document.cookie
			} )
		}
	);
	
	// Muss "true" zurückgeben, damit der Standarderrorhandler nicht ausgeführt wird
	// Das soll aber nur in Produktion passieren, wo die apiUrl nur /api ist.
	return apiUrl === "/api";
};

ReactDOM.render(
	<React.StrictMode>
		<Provider store={reduxStore}>
			<App/>
		</Provider>
	</React.StrictMode>,
	document.getElementById( "root" )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
