import "./ResultsSources.css";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../reducer";
import { ISource } from "../interfaces";
import { selectResultsView } from "../actions";
import Loader from "./Loader";
import { useResult } from "../customHooks";

const Source = ( source: ISource ) =>
{
	const { id, name, options } = source;
	
	const dispatch        = useDispatch();
	const selectedSources = useSelector( ( state: IState ) => state.selectedSources );
	const result          = useResult( id );
	const resultsView     = useSelector( ( state: IState ) => state.resultsView );
	
	const ids = useMemo(
		() =>
		{
			const getChildIds = ( source: ISource ): string[] =>
			{
				if ( source.disabled )
				{
					return [];
				}
				
				if ( source.id )
				{
					return [ source.id ];
				}
				else if ( source.options.length > 0 )
				{
					let ids: string[] = [];
					source.options.forEach(
						option =>
						{
							ids = ids.concat( getChildIds( option ) );
						}
					);
					return ids;
				}
				return [];
			};
			
			return getChildIds( source );
		},
		[ source ]
	);
	
	const isVisible = useMemo(
		() =>
		{
			if ( !id )
			{
				if ( ids.length === 0 )
				{
					return false;
				}
				
				return ids.filter( idIterator => selectedSources.includes( idIterator ) ).length > 0;
			}
			
			return selectedSources.includes( id );
		},
		[ id, ids, selectedSources ]
	);
	
	if ( !isVisible )
	{
		return null;
	}
	
	const title = !result
		? `Ergebnisse für ${name} werden geladen.`
		: (
			result?.success
				? `${result.totalResults.toLocaleString()} Ergebnisse für ${name} gefunden.`
				: ( result.messages.info || `Es konnten keine Ergebnisse für ${name} geladen werden.` )
		);
	
	return <li className={`${resultsView === id ? "active" : ""} ${result ? ( !result.success ? "error" : "" ) : "loading"}`}>
		{
			id
				? <button
					onClick={() => resultsView !== id && dispatch( selectResultsView( id ) )}
					title={title}
					disabled={!result || ( result?.totalResults === 0 )}>
					<span>{name}</span>
					<span className={"count"}>{
						!result
							? <Loader size={"0.9rem"}/>
							: (
								!result?.success
									? <div className={"error"}/>
									: <span>[{result.totalResults.toLocaleString()}]</span>
							)
					}</span>
				</button>
				: <label>
					<span>{name}</span>
				</label>
		}
		{
			options?.length > 0 &&
			<ul>
				{options.map( ( option: ISource, index: number ) => <Source key={`source-${index}`} {...option}/> )}
			</ul>
		}
	</li>;
};

const ResultsSources = () =>
{
	const allSources = useSelector( ( state: IState ) => state.allSources );
	
	return <div className={"ResultsSources"}>
		<h3>Quellen</h3>
		<ul>
			{
				allSources.map( ( source: ISource, index: number ) => <Source key={`source-${index}`} {...source} /> )
			}
		</ul>
	</div>;
};


export default ResultsSources;