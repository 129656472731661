import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { storeSet } from "../actions";
import { Session } from "../storage";

interface IParams
{
	jwt: string
}

const JWT = () =>
{
	const dispatch = useDispatch();
	const { jwt }  = useParams<IParams>();
	
	dispatch( storeSet( "jwt", jwt ) );
	Session.save( "jwt", jwt );
	
	return <Redirect to={"/"}/>;
};

export default JWT;