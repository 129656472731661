import "./Header.css";
import Navigation from "./Navigation";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLogout } from "../actions";
import { IState } from "../reducer";

const Header = () =>
{
	const dispatch    = useDispatch();
	const logout      = () => dispatch( fetchUserLogout() );
	const environment = useSelector( ( state: IState ) => state.environment );
	const username    = useSelector( ( state: IState ) => state.username );
	
	return <header>
		<div className={"banner"}>
			<div className={"container"}>
				<a href={"https://www.soldan.de"}>&lt; Zurück zu Soldan.de</a>
			</div>
		</div>
		<div className={"topBar container"}>
			{
				username !== ""
					? <Fragment>
						<span>Willkommen, {username}</span>
						<button onClick={logout}>Abmelden</button>
					</Fragment>
					: <div style={{ height: 23 }}/>
			}
		</div>
		<div className={"main container"}>
			<img src={"/soldanFachsuche.png"} width={465} height={49} alt={""}/>
			{environment && <span className={"environment"}>[{environment}]</span>}
		</div>
		<Navigation/>
	</header>;
};

export default Header;
