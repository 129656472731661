import "./Pagination.css";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../reducer";
import { useCallback, useMemo } from "react";
import { storeSet } from "../actions";
import { useResult } from "../customHooks";

const SingleArrow = ( { mirror = false } ) =>
	<svg width="10px" height="10px" viewBox="-4 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<path transform={mirror ? "rotate(180) translate(-18,-25)" : "translate(-9 -5)"}
		      d="M15.091,15.681a1.97,1.97,0,0,0,.278-.732,1,1,0,0,0-.278-.679L9.574,8.538a2.116,2.116,0,0,1-.01-2.887l.028-.03a1.958,1.958,0,0,1,2.854-.008l8.267,8.613a1.077,1.077,0,0,1,.287.723,2.115,2.115,0,0,1-.287.775l-8.267,8.665a1.959,1.959,0,0,1-2.854-.012l-.028-.036a2.134,2.134,0,0,1,.01-2.9Z"
		      fill="#666666"/>
	</svg>;

const DoubleArrow = ( { mirror = false } ) =>
	<svg width="10px" height="10px" viewBox="0 -2 24 24" xmlns="http://www.w3.org/2000/svg">
		<g transform={mirror ? "rotate(180) translate(-28 -27)" : "translate(-4 -6)"}>
			<path
				d="M22.091,16.681a1.97,1.97,0,0,0,.278-.732,1,1,0,0,0-.278-.679L16.574,9.538a2.116,2.116,0,0,1-.01-2.887l.028-.03a1.958,1.958,0,0,1,2.854-.008l8.267,8.613a1.077,1.077,0,0,1,.287.723,2.115,2.115,0,0,1-.287.775l-8.267,8.665a1.959,1.959,0,0,1-2.854-.012l-.028-.036a2.134,2.134,0,0,1,.01-2.9Z"
				fill="#666666"/>
			<path
				d="M10.091,16.681a1.97,1.97,0,0,0,.278-.732,1,1,0,0,0-.278-.679L4.574,9.538a2.116,2.116,0,0,1-.01-2.887l.028-.03a1.958,1.958,0,0,1,2.854-.008l8.267,8.613a1.077,1.077,0,0,1,.287.723,2.115,2.115,0,0,1-.287.775L7.446,25.389a1.959,1.959,0,0,1-2.854-.012l-.028-.036a2.134,2.134,0,0,1,.01-2.9Z"
				fill="#666666"/>
		</g>
	</svg>;

const Pagination = () =>
{
	const page     = useSelector( ( state: IState ) => state.page );
	const dispatch = useDispatch();
	const setPage  = useCallback(
		( page: number ) => dispatch( storeSet( "page", page ) ),
		[ dispatch ]
	);
	const result   = useResult();
	
	const pageCount = useMemo(
		(): number =>
		{
			if ( !result )
			{
				return 0;
			}
			
			const { resultsPerPage, totalResults } = result;
			if ( totalResults === 0 )
			{
				return 0;
			}
			
			return Math.ceil( totalResults / resultsPerPage );
		},
		[ result ]
	);
	
	return <div className={"Pagination"}>
		<button disabled={pageCount === 0 || page === 1} onClick={() => setPage( 1 )}><DoubleArrow mirror={true}/></button>
		<button disabled={pageCount === 0 || page === 1} onClick={() => setPage( page - 1 )}><SingleArrow mirror={true}/></button>
		
		{/*{*/}
		{/*	( new Array( page ) ).fill( undefined )*/}
		{/*	                     .map( ( _, index ) => index )*/}
		{/*	                     .filter( index => index > 0 && ( page - 5 < index ) )*/}
		{/*	                     .map(*/}
		{/*		                     index => <button key={`page-${index}`} onClick={() => setPage( index )}>{index}</button>*/}
		{/*	                     )*/}
		{/*}*/}
		
		<button className={"active"}>{page.toLocaleString()}</button>
		
		{/*{*/}
		{/*	( new Array( pageCount ) ).fill( undefined )*/}
		{/*	                     .map( ( _, index ) => index )*/}
		{/*	                     .filter( index => index > page && ( index < ( page + 5 ) ) )*/}
		{/*	                     .map(*/}
		{/*		                     index => <button key={`page-${index}`} onClick={() => setPage( index )}>{index}</button>*/}
		{/*	                     )*/}
		{/*}*/}
		
		<button disabled={pageCount === 0 || page === pageCount} onClick={() => setPage( page + 1 )}><SingleArrow/></button>
		<button disabled={pageCount === 0 || page === pageCount} onClick={() => setPage( pageCount )}><DoubleArrow/></button>
		<span>von <b>{pageCount?.toLocaleString() || 1}</b></span>
		{result && <div className={"resultsPerPage"}>Ergebnisse pro Seite: {result.resultsPerPage}</div>}
	</div>;
};

export default Pagination;