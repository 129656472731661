import "./Facets.css";
import { useDispatch } from "react-redux";
import { useResult, useSelectedFacetValues } from "../customHooks";
import { IFacet, IFacetValue } from "../interfaces";
import { Fragment, useCallback, useMemo, useState } from "react";
import { refreshIntrasearchFacet, storeSet, toggleFacet } from "../actions";

const Value = ( facetValue: IFacetValue ) =>
{
	const dispatch            = useDispatch();
	const selectedFacetValues = useSelectedFacetValues();
	const toggleFacetValue    = () => dispatch( toggleFacet( facetValue.value ) );
	
	const isSelected = useMemo(
		() =>
		{
			return selectedFacetValues.find( facet => facet === facetValue.value ) !== undefined;
		},
		[ facetValue.value, selectedFacetValues ]
	);
	
	let valueText = facetValue.title.replace( /[\u202F\u00A0]/, " " );
	
	// Unsichtbare Zeichen am Anfang sichtbar machen
	if ( valueText.substring( 0, 1 ) === " " )
	{
		valueText = "␣" + valueText.trim();
	}
	
	let displayValue = <>{valueText}</>;
	
	// Werte mit Name und E-Mailadresse lesbarer formatieren
	if ( valueText.match( /.*\(.*?@.*?\..*?\)/ ) )
	{
		displayValue = <div>{valueText.substring( 0, valueText.indexOf( " (" ) )}
			<div className={"email"}>{valueText.substring( valueText.indexOf( " (" ) + 1 )}</div>
		</div>;
	}
	
	return <li key={facetValue.value} className={isSelected ? "active" : ""}>
		<button onClick={toggleFacetValue}>
			<div className={"count"}>[{facetValue.count.toLocaleString()}]</div>
			{displayValue}
		</button>
	</li>;
};

const Facet = ( facet: IFacet ) =>
{
	const dispatch                  = useDispatch();
	const selectedFacetValues       = useSelectedFacetValues();
	const [ expanded, setExpanded ] = useState(
		selectedFacetValues.filter( selectedFacetValue => selectedFacetValue.startsWith( facet.name ) ).length > 0
	);
	
	const increaseLimit = useCallback(
		() =>
		{
			dispatch( storeSet( "intrasearchFacetLimit", { [facet.name]: facet.values.length + facet.limit } ) );
			dispatch( refreshIntrasearchFacet( facet.name ) );
		},
		[ dispatch, facet ]
	);
	const extendFacet   = useCallback(
		() =>
		{
			dispatch( storeSet( "intrasearchExtendedFacet", { [facet.name]: facet.extensionValue + 1 } ) );
			dispatch( refreshIntrasearchFacet( facet.name ) );
		},
		[ dispatch, facet ]
	);
	
	if ( facet.values.length === 0 )
	{
		return null;
	}
	
	return <li className={expanded ? "expanded" : undefined}>
		<button onClick={() => setExpanded( !expanded )}>{expanded ? "▲" : "▼"} {facet.title}</button>
		{
			expanded &&
			<Fragment>
				<ul className={"values"}>
					{
						facet.values.map(
							facetValue => <Value key={facetValue.value} {...facetValue} />
						)
					}
				</ul>
				{facet.totalCount > facet.values.length &&
				<button onClick={increaseLimit} className={"more"}>{facet.totalCount - facet.values.length} weitere...</button>}
				{facet.extensionAvailable > 0 &&
				<button onClick={extendFacet} className={"more"}>weitere...</button>}
			</Fragment>
		}
	</li>;
};

const Facets = () =>
{
	const result = useResult();
	
	// Wenn keine Facetten angezeigt werden den ganzen Block nicht anzeigen.
	if ( result?.facets.filter( facet => facet.values.length > 0 ).length === 0 )
	{
		return null;
	}
	
	return <div className={"Facets"}>
		<h3>Filteroptionen</h3>
		<ul>
			{result?.facets.map( facet => <Facet key={facet.name} {...facet} /> )}
		</ul>
	</div>;
};

export default Facets;