import { Dispatch } from "redux";
import { IState } from "./reducer";
import { IFacet, IIntrasearchResult, IIntrasearchResults, IIntrasearchWhoami, IResult, ISearchtypeResult, ISource } from "./interfaces";
import { MessageType } from "./components/UserMessage";
import { apiUrl, wsUrl } from "./const";
import { Session } from "./storage";

export enum Action
{
	SET,
	MULTI_SET,
	FILTER,
	CONCAT,
	MERGE,
	PROCESS_SEARCHRESULT,
	SELECTRESULTSVIEW,
	REFRESHFACET,
	TOGGLEFACET,
	SHOWUSERMESSAGE
}

export interface IAction
{
	type: keyof Action,
	filter: () => boolean,
	name: keyof IState,
	value: any,
	values: Partial<IState>,
	page: number,
	resultsView: string,
	result: ISearchtypeResult,
	facet: IFacet,
	facetValue: string
}

export const storeSet = ( name: keyof IState, value: any ) =>
{
	return {
		type: Action.SET,
		name,
		value
	};
};

export const storeMultiSet = ( values: Partial<IState> ) =>
{
	return {
		type: Action.MULTI_SET,
		values
	};
};

export const storeFilter = ( name: keyof IState, filter: any ) =>
{
	return {
		type: Action.FILTER,
		name,
		filter
	};
};

export const storeConcat = ( name: keyof IState, value: any ) =>
{
	return {
		type: Action.CONCAT,
		name,
		value
	};
};

export const storeMerge = ( name: keyof IState, value: any ) =>
{
	return {
		type: Action.MERGE,
		name,
		value
	};
};

export const processSearchresult = ( result: ISearchtypeResult ) =>
{
	return {
		type: Action.PROCESS_SEARCHRESULT,
		result
	};
};

export const showUserMessage = ( type: MessageType, message: string ) =>
{
	const id = performance.now();
	
	return {
		type:  Action.SHOWUSERMESSAGE,
		value: { id, type, message }
	};
};

export const selectResultsView = ( resultsView = "" ) =>
{
	return {
		type: Action.SELECTRESULTSVIEW,
		resultsView
	};
};

export const refreshFacet = ( facet: IFacet ) =>
{
	return {
		type: Action.REFRESHFACET,
		facet
	};
};

export const toggleFacet = ( facetValue: string ) =>
{
	return {
		type: Action.TOGGLEFACET,
		facetValue
	};
};

const renewJWT = () =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const { jwt } = state();
		
		await fetch(
			`${apiUrl}/rest/renewJWT`,
			{
				method:      "POST",
				credentials: "include",
				headers:     {
					JWT: jwt
				}
			}
		).then(
			response =>
			{
				if ( response.ok )
				{
					response.json().then(
						json =>
						{
							dispatch( storeSet( "jwt", json.jwt ) );
							Session.save( "jwt", json.jwt );
							
							if ( json.jwtExpiresIn > 0 )
							{
								setTimeout(
									// @ts-ignore
									() => dispatch( renewJWT() ),
									( json.jwtExpiresIn - 60 ) * 1000
								);
							}
						}
					);
				}
			}
		).catch(
			() =>
			{
				Session.remove( "jwt" );
				dispatch( storeSet( "jwt", "" ) );
			}
		);
	};
};

export const fetchUserInformation = () =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const { jwt, selectedSources } = state();
		
		// Beim Seite verlassen/neu laden den Request abbrechen
		const controller = new AbortController();
		const { signal } = controller;
		window.onunload  = () => controller.abort();
		
		await fetch(
			`${apiUrl}/rest/userinfo`,
			{
				credentials: "include",
				headers:     {
					JWT: jwt
				},
				signal
			}
		).then(
			response =>
			{
				if ( response.ok )
				{
					const environment = response.headers.get( "X-Environment" ) || "";
					if ( environment )
					{
						document.title += ` [${environment}]`;
					}
					
					response.json().then(
						json =>
						{
							dispatch(
								storeMultiSet(
									Object.assign(
										{},
										json,
										{
											environment,
											selectedSources: selectedSources.filter(
												source => json.allSources.find( ( category: any ) => category.options.find( ( sourceIterator: any ) => sourceIterator.id === source && !sourceIterator.disabled ) )
											)
										}
									)
								)
							);
							
							if ( json.jwtExpiresIn > 0 )
							{
								setTimeout(
									// @ts-ignore
									() => dispatch( renewJWT() ),
									( json.jwtExpiresIn - 60 ) * 1000
								);
							}
						}
					);
				}
				// JWT nicht mehr gültig, weil zB der Server neu gestartet wurde
				else if ( response.status === 403 )
				{
					Session.remove( "jwt" );
					dispatch( storeSet( "jwt", "" ) );
				}
			}
		).catch(
			error =>
			{
				// Wenn der Benutzer nicht durch Seitenwechsel/Seite neu laden den Request abgebrochen hat,
				// sondern ein Fehler aufgetreten ist, dann soll der JWT gelöscht und der Benutzer zu Soldan
				// umgeleitet werden.
				if ( error.name !== "AbortError" )
				{
					Session.remove( "jwt" );
					dispatch( storeSet( "jwt", "" ) );
				}
			}
		);
	};
};

export const fetchUserLogout = () =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const { jwt } = state();
		
		await fetch(
			`${apiUrl}/rest/logout`,
			{
				method:      "POST",
				credentials: "include",
				headers:     {
					JWT: jwt
				}
			}
		).then(
			response =>
			{
				if ( response.ok )
				{
					Session.remove( "jwt" );
					dispatch(
						storeMultiSet(
							{
								username:       "",
								jwt:            "",
								intrasearchJwt: "",
								allSources:     [],
								linkList:       []
							}
						)
					);
				}
			}
		).catch(
			error => console.error( error )
		);
	};
};

export const search = ( source = "" ) =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const { facets, allSources, jwt, selectedSources, page, searchtext, results, websocket } = state();
		
		const searchtype = source || selectedSources.filter(
			source => source !== "INTRASEARCH" && allSources.find( category => category.options.find( sourceIterator => sourceIterator.id === source ) )
		).join( "," );
		
		if ( source === "" )
		{
			dispatch(
				storeMultiSet(
					{
						results:     [],
						page:        1,
						facets:      {},
						resultsView: ""
					}
				)
			);
			
			if ( selectedSources.includes( "INTRASEARCH" ) )
			{
				// @ts-ignore
				dispatch( fetchIntrasearchResults() );
			}
		}
		else
		{
			// Prüfen, ob gewünschte Ergebnisse schon vorliegen
			const result = results.find( ( searchtypeResult: ISearchtypeResult ) => searchtypeResult.name === source );
			if ( !result || result.rows[( page - 1 ) * result.resultsPerPage] !== undefined )
			{
				return;
			}
			
			if ( source === "INTRASEARCH" )
			{
				// @ts-ignore
				dispatch( fetchIntrasearchSpecificResults() );
				return;
			}
		}
		
		if ( searchtype !== "" )
		{
			const sendMessage = ( socket: WebSocket ) => socket.send(
				JSON.stringify(
					{
						searchtext,
						searchtype,
						page:   source === "" ? 1 : page,
						facets: facets.hasOwnProperty( searchtype ) ? facets[searchtype] : []
					}
				)
			);
			
			// Websocketverbindung aufbauen, falls noch nicht vorhanden
			if ( websocket === null )
			{
				const socket = new WebSocket( `${wsUrl}?jwt=${jwt}` );
				
				socket.onmessage = ( event ) =>
				{
					try
					{
						const json: ISearchtypeResult = JSON.parse( event.data );
						
						if ( !json.success || json.messages.info )
						{
							for ( let key in allSources )
							{
								const source = allSources[key].options.find( source => source.id === json.name );
								if ( source )
								{
									dispatch(
										showUserMessage(
											json.success ? MessageType.INFO : MessageType.ERROR,
											`${source.name}: ${json.messages.info || "Suchergebnisse konnten nicht geladen werden."}`
										)
									);
								}
							}
						}
						
						dispatch( processSearchresult( json ) );
						
						if ( json.page === 1 && json.success )
						{
							dispatch( selectResultsView() );
						}
					}
					catch ( error )
					{
					
					}
				};
				
				socket.onopen = () => sendMessage( socket );
				
				socket.onerror = () =>
				{
					Session.remove( "jwt" );
					dispatch( storeSet( "jwt", "" ) );
				};
				
				socket.onclose = event =>
				{
					// User nicht mehr eingeloggt.
					if ( event.code === 4403 )
					{
						Session.remove( "jwt" );
						dispatch( storeMultiSet( { websocket: null, jwt: "" } ) );
						return;
					}
					
					dispatch( storeSet( "websocket", null ) );
				};
				
				dispatch( storeSet( "websocket", socket ) );
				return;
			}
			
			sendMessage( websocket );
		}
	};
};

export const openSSOLink = ( token: string ) =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const { jwt, openingSSOLinks } = state();
		
		if ( openingSSOLinks.includes( token ) )
		{
			return;
		}
		dispatch( storeConcat( "openingSSOLinks", token ) );
		
		await fetch(
			`${apiUrl}/rest/redirect?token=${token}`,
			{
				credentials: "include",
				headers:     {
					JWT: jwt
				}
			}
		).then(
			response =>
			{
				dispatch( storeFilter( "openingSSOLinks", ( url: string ): boolean => url !== token ) );
				if ( response.ok )
				{
					response.text().then(
						url =>
						{
							// Damit das Öffnen bei Juris immer funktioniert muss der Benutzer vorher ausgeloggt werden.
							if ( url.substring( 0, 19 ) === "http://www.juris.de" )
							{
								const win = window.open( "https://www.juris.de/jportal/portal/page/jurisw.psml/destiny/toHomepage?action=JLogoutUser" );
								
								if ( win )
								{
									setTimeout( () =>
									{
										win.location.href = url;
									}, 500 );
								}
							}
							else
							{
								window.open( url );
							}
						}
					);
				}
				else
				{
					dispatch( showUserMessage( MessageType.ERROR, "Dokument konnte nicht geöffnet werden." ) );
				}
			}
		).catch(
			error =>
			{
				dispatch( showUserMessage( MessageType.ERROR, "Dokument konnte nicht geöffnet werden." ) );
			}
		);
	};
};

/**
 * INTRASEARCH
 */
const getIntrasearchApiUrl = ( allSources: ISource[] ) =>
{
	let url = "";
	
	allSources.forEach(
		source => source.options.forEach(
			option =>
			{
				if ( option.id === "INTRASEARCH" && option.url )
				{
					url = option.url;
				}
			}
		)
	);
	
	return url;
};

const fetchIntrasearchJwt = ( callback: () => void ) =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const { allSources } = state();
		
		const apiUrl = getIntrasearchApiUrl( allSources );
		if ( apiUrl === "" )
		{
			return;
		}
		
		await fetch(
			`${apiUrl}/api/v1/whoami`,
			{
				credentials: "include"
			}
		).then(
			response =>
			{
				if ( response.ok )
				{
					response.json().then( ( json: IIntrasearchWhoami ) =>
					{
						dispatch( storeSet( "intrasearchJwt", json.jwt ) );
						
						// @ts-ignore
						dispatch( callback() );
					} );
				}
			}
		).catch(
			error => console.error( error )
		);
	};
};

const intrasearchResultToSoldanFormat = ( result: IIntrasearchResult ): IResult => (
	{
		id:    result.id,
		date:  ( new Date( result.date ) ).toLocaleDateString( undefined, { day: "2-digit", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit" } ),
		link:  result.location,
		title: result.title,
		text:  result.text
	}
);

const intrasearchToSoldanFormat = ( url: string, searchtext: string, json: IIntrasearchResults ): ISearchtypeResult => (
	{
		page:           ( json.offset / json.limit ) + 1,
		facets:         json.facets,
		searchtext,
		name:           "INTRASEARCH",
		resultsPerPage: json.limit,
		messages:       {},
		rows:           json.results.map( intrasearchResultToSoldanFormat ),
		success:        true,
		totalResults:   json.count,
		url
	}
);

const fetchIntrasearchResults = () =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const { searchtext, allSources, intrasearchJwt } = state();
		
		if ( !intrasearchJwt )
		{
			// @ts-ignore
			dispatch( fetchIntrasearchJwt( fetchIntrasearchResults ) );
			return;
		}
		
		const apiUrl = getIntrasearchApiUrl( allSources );
		if ( apiUrl === "" )
		{
			return;
		}
		
		let queryString = `query=${encodeURIComponent( searchtext )}`;
		
		await fetch(
			`${apiUrl}/api/v1/results?${queryString}`,
			{
				credentials: "include",
				headers:     {
					jwt: intrasearchJwt
				}
			}
		).then(
			response =>
			{
				if ( response.ok )
				{
					response.json().then( ( json: IIntrasearchResults ) =>
					{
						dispatch( processSearchresult( intrasearchToSoldanFormat( apiUrl, searchtext, json ) ) );
						dispatch( selectResultsView() );
					} );
				}
			}
		).catch(
			error => console.error( error )
		);
	};
};

const fetchIntrasearchSpecificResults = () =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const { allSources, intrasearchJwt, intrasearchFacetLimit, intrasearchExtendedFacet, searchtext, page, facets, results } = state();
		
		const apiUrl = getIntrasearchApiUrl( allSources );
		if ( apiUrl === "" )
		{
			return;
		}
		
		const result = results.find( ( searchtypeResult: ISearchtypeResult ) => searchtypeResult.name === "INTRASEARCH" );
		if ( !result )
		{
			return;
		}
		
		let queryString = `query=${encodeURIComponent( searchtext )}`
			+ `&offset=${( page - 1 ) * result.resultsPerPage}`;
		
		if ( facets.hasOwnProperty( "INTRASEARCH" ) )
		{
			queryString += facets["INTRASEARCH"].map( facet => `&facet[]=${facet}` ).join( "" );
		}
		
		for ( const [ facet, limit ] of Object.entries( intrasearchFacetLimit ) )
		{
			queryString += `&facet_limit[${facet}]=${limit}`;
		}
		
		for ( const [ facet, extension ] of Object.entries( intrasearchExtendedFacet ) )
		{
			queryString += `&extend_facet[${facet}]=${extension}`;
		}
		
		await fetch(
			`${apiUrl}/api/v1/results?${queryString}`,
			{
				credentials: "include",
				headers:     {
					jwt: intrasearchJwt
				}
			}
		).then(
			response =>
			{
				if ( response.ok )
				{
					response.json().then(
						( json: IIntrasearchResults ) => dispatch( processSearchresult( intrasearchToSoldanFormat( apiUrl, searchtext, json ) ) )
					);
				}
			}
		).catch(
			error => console.error( error )
		);
	};
};

export const openInIntrasearch = ( id: string ) =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const { allSources, intrasearchJwt } = state();
		
		const apiUrl = getIntrasearchApiUrl( allSources );
		if ( apiUrl === "" )
		{
			return;
		}
		
		await fetch(
			`${apiUrl}/api/v1/searchResults/${id}/actions/open`,
			{
				credentials: "include",
				headers:     {
					jwt: intrasearchJwt
				}
			}
		).then(
			response =>
			{
				if ( !response.ok )
				{
					dispatch( showUserMessage( MessageType.ERROR, "Suchergebnis konnte nicht geöffnet werden!" ) );
				}
			}
		).catch(
			error => console.error( error )
		);
	};
};

export const refreshIntrasearchFacet = ( name: string ) =>
{
	return async ( dispatch: Dispatch, state: () => IState ) =>
	{
		const {
			      allSources,
			      intrasearchJwt,
			      searchtext,
			      facets,
			      intrasearchFacetLimit,
			      intrasearchExtendedFacet,
			      results
		      } = state();
		
		const apiUrl = getIntrasearchApiUrl( allSources );
		if ( apiUrl === "" )
		{
			return;
		}
		
		const result = results.find( ( searchtypeResult: ISearchtypeResult ) => searchtypeResult.name === "INTRASEARCH" );
		if ( !result )
		{
			return;
		}
		
		let queryString = `query=${encodeURIComponent( searchtext )}`;
		
		if ( facets.hasOwnProperty( "INTRASEARCH" ) )
		{
			queryString += facets["INTRASEARCH"].map( facet => `&facet[]=${facet}` ).join( "" );
		}
		
		for ( const [ facet, limit ] of Object.entries( intrasearchFacetLimit ) )
		{
			queryString += `&facet_limit[${facet}]=${limit}`;
		}
		
		for ( const [ facet, extension ] of Object.entries( intrasearchExtendedFacet ) )
		{
			queryString += `&extend_facet[${facet}]=${extension}`;
		}
		
		await fetch(
			`${apiUrl}/api/v1/results?${queryString}`,
			{
				credentials: "include",
				headers:     {
					jwt: intrasearchJwt
				}
			}
		).then(
			response =>
			{
				if ( response.ok )
				{
					response.json().then( ( json: IIntrasearchResults ) =>
					{
						const facet = json.facets.find( facet => facet.name === name );
						if ( facet )
						{
							dispatch( refreshFacet( facet ) );
						}
					} );
				}
			}
		).catch(
			error => console.error( error )
		);
	};
};
