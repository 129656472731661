import "./Search.css";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useCallback, useEffect, useState } from "react";
import { search, showUserMessage, storeMultiSet } from "../actions";
import { IState } from "../reducer";
import { useHistory } from "react-router-dom";
import Sources from "./Sources";
import { MessageType } from "./UserMessage";
import { ssoUrl } from "../const";

const SearchIcon = () =>
	<svg width="28.045px" height="28.045px" viewBox="0 -0.02 28.045 28.045" xmlns="http://www.w3.org/2000/svg">
		<path transform="translate(-2 -2)"
		      d="M14,6a7.987,7.987,0,0,1,6.52,12.619l-.789,1.112-1.112.789A8,8,0,1,1,14,6m0-4a12,12,0,1,0,6.936,21.781l5.635,5.636a1.993,1.993,0,0,0,2.817,0l.029-.029a1.9,1.9,0,0,0,0-2.817l-5.636-5.635A11.988,11.988,0,0,0,14,2Z"
		      fill="#CCCCCC"/>
	</svg>;

const Search = ( { small = false } ) =>
{
	const searchtext                      = useSelector( ( state: IState ) => state.searchtext );
	const [ searchInput, setSearchInput ] = useState( searchtext );
	const dispatch                        = useDispatch();
	const history                         = useHistory();
	const jwt                             = useSelector( ( state: IState ) => state.jwt );
	
	const allSources                          = useSelector( ( state: IState ) => state.allSources );
	const selectedSources                     = useSelector( ( state: IState ) => state.selectedSources );
	const [ searchSources, setSearchSources ] = useState( selectedSources );
	
	// Damit auch gespeicherte Suchtypen nicht angehakt sind, wenn sie deaktiviert wurden.
	useEffect(
		() =>
		{
			setSearchSources( selectedSources );
		},
		[ selectedSources ]
	);
	
	const onSourceChange = useCallback(
		( isChecked, ids ) =>
		{
			setSearchSources(
				oldIds =>
					!isChecked
						? oldIds.concat( ids )
						: oldIds.filter( id => !ids.includes( id ) )
			);
		},
		[]
	);
	
	const [ overlay, setOverlay ] = useState( false );
	const showOverlay             = () =>
	{
		if ( !small )
		{
			return;
		}
		
		setOverlay( true );
	};
	const hideOverlay             = () => setOverlay( false );
	
	const doSearch = () =>
	{
		if ( searchInput.trim().length === 0 )
		{
			dispatch( showUserMessage( MessageType.ERROR, "Bitte geben Sie einen Suchbegriff ein." ) );
			return;
		}
		
		if ( searchSources.filter( searchSource => allSources.find( category => category.options.find( source => source.id === searchSource && !source.disabled ) ) ).length === 0 )
		{
			dispatch( showUserMessage( MessageType.ERROR, "Bitte wählen Sie mindestens eine Quelle aus." ) );
			return;
		}
		
		dispatch( storeMultiSet( { selectedSources: searchSources, userMessages: [], searchtext: searchInput.trim() } ) );
		
		dispatch( search() );
		history.push( "/results" );
		setOverlay( false );
	};
	
	// Benutzer nicht eingeloggt -> zur SSO-Seite weiterleiten
	if ( !jwt )
	{
		window.location.href = ssoUrl;
		return <Fragment/>;
	}
	
	return <div className={`Search ${small ? "small" : ""} ${overlay ? "overlay" : ""}`} onMouseLeave={hideOverlay}>
		<div>
			<h2>Suche</h2>
			<form onSubmit={e => e.preventDefault()}>
				<div className={"searchhead"}>
					<label htmlFor={"searchInput"}>Ihr Suchbegriff:</label>
					<div className={"searchfield"}>
						<SearchIcon/>
						<input
							id={"searchInput"}
							onFocus={showOverlay} onClick={showOverlay}
							autoFocus={!small} type={"text"} value={searchInput} onChange={event => setSearchInput( event.target.value )}/>
					</div>
					<button onClick={doSearch}>Jetzt suchen</button>
				</div>
				<Sources small={small} searchSources={searchSources} onChange={onSourceChange}/>
			</form>
		</div>
	</div>;
};

export default Search;